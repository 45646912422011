<template>
	<v-layout row justify-center align-center>
		<TasksManagerFilterDueDate :xs4="isAccountant" :xs6="!isAccountant" :minimized="isMinimized" class="shrink" />
		<TasksManagerFilterStatus :xs2="isAccountant" :xs3="!isAccountant" :minimized="isMinimized" />
		<TasksManagerFilterTheme :xs3="isAccountant" :xs4="!isAccountant" :minimized="isMinimized" />
		<TasksManagerFilterMember v-if="isAccountant" xs3 :minimized="isMinimized" />
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerToolbarFilters',
	components: {
		TasksManagerFilterStatus: () => ({
			component: import('@/components/TasksManager/Filters/TasksManagerFilterStatus')
		}),
		TasksManagerFilterDueDate: () => ({
			component: import('@/components/TasksManager/Filters/TasksManagerFilterDueDate')
		}),
		TasksManagerFilterTheme: () => ({
			component: import('@/components/TasksManager/Filters/TasksManagerFilterTheme')
		}),
		TasksManagerFilterMember: () => ({
			component: import('@/components/TasksManager/Filters/TasksManagerFilterMember')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant
		}),
		isMinimized: function () {
			return this.$vuetify.breakpoint.mdAndDown || this.minimized
		}
	}
}
</script>
